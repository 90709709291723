//Mobile first

body {
  overflow: none;
}

.text-zone {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  margin-top: 15rem;
  color: white;
  font-size: 40px;
  font-weight: 600;
  animation: fadeIn 1s 0.7s backwards;
}

h2 {
  margin-left: 2rem;
  color: #6f1e2c;
  font-size: 30px;
  font-weight: 400;
  animation: fadeIn 1s 1s backwards;
}

.button {
  align-items: center;
  justify-content: center;
  font-weight: 600;
  height: 15px;
  width: 85px;
  align-items: center;
  color: #000000;
  background-color: aliceblue;
  font-size: 13px;
  font-weight: 400px;
  text-decoration: none;
  padding: 10px 18px;
  border: 1.5px solid #6f1e2c;
  border-radius: 20px;
  float: left;
  margin-left: 2rem;
  animation: fadeIn 1s 1.4s backwards;
}

.button:hover {
  background-color: rgb(243, 106, 136);
  color: black;
  box-shadow: 0 0 5px 0px rgb(96, 31, 57);
}

.container-icon {
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin-top: 4rem;
  margin-left: 2rem;
  margin-right: 2rem;
}

.container-icon path {
  fill: transparent;
  stroke-width: 10;
  stroke: #7c2c3a;
}

.operator {
  animation: fadeIn 1s 1.5s backwards;
  margin-right: 5rem;
  margin-top: 3rem;
  width: 60px;
  height: 60px;
}

.operator:hover {
  fill: #7c2c3a;
}

.container-icon .heart path {
  stroke-dasharray: 635;
  stroke-dashoffset: 0;
}

.heart {
  font-size: 10rem;
  animation: fadeIn 1s 1.7s backwards;
}

@keyframes animate-heart {
  0% {
    stroke-dashoffset: 0;
  }
  40% {
    stroke-dashoffset: 634;
  }
  80% {
    stroke-dashoffset: 1.268;
    fill: transparent;
  }
  100% {
    stroke-dashoffset: 1.268;
    fill: pink;
  }
}

.container-icon .heart path:hover {
  animation: animate-heart 2s linear forwards;
}

.operators {
  animation: fadeIn 1s 1.9s backwards;
  margin-left: 5rem;
  margin-top: 3rem;
  width: 60px;
  height: 60px;
}

@media screen and (min-width: 800px) {
  .container-icon {
    cursor: pointer;
    display: flex;
    justify-content: center;
  }
}

@media screen and (min-width: 950px) {
  .home-page {
    max-width: 100%;
    max-height: 60%;
  }

  .logo-animation {
    display: block;
    float: right;
    font-size: 5rem;
    margin-left: 5rem;
  }

  .text-zone {
    margin-top: 6rem;
  }

  .button {
    display: flex;
    height: 20px;
    width: 120px;
    font-size: 1.6rem;
  }
  .title {
    font-size: 6rem;
  }

  h2 {
    font-size: 4rem;
  }
  .text-zone {
    margin-top: 6rem;
  }

  .button {
    display: flex;
    height: 20px;
    width: 120px;
    font-size: 1.6rem;
  }
}
