//Mobile first

@media screen and (max-width: 799px) {
  .link {
    list-style-type: none;
    margin-bottom: 3rem;
    display: flex;
    flex-direction: column;
    color: white;
  }

  .page {
    width: 100%;
    height: 100%;
    position: absolute;
  }

  .top-tags {
    bottom: auto;
    top: 35px;
  }

  .logo {
    display: flex;
    justify-content: center;
    text-align: center;
    width: 40px;
    height: 20px;
    background-color: rgb(255, 255, 255);
    border-radius: 50%;
    text-align: center;
    font-size: 2rem;
    margin-right: 30rem;
    margin-bottom: 5px;
    margin-top: 1rem;
    margin-left: 1.5rem;
  }

  .faM {
    color: rgb(91, 17, 54);
  }
  a:link {
    text-decoration: none;
  }

  a:visited {
    text-decoration: none;
  }

  a:hover {
    text-decoration: none;
  }

  a:active {
    text-decoration: none;
  }
}

@media screen and (min-width: 800px) {
  a:link {
    text-decoration: none;
  }

  a:visited {
    text-decoration: none;
  }

  a:hover {
    text-decoration: none;
  }

  a:active {
    text-decoration: none;
  }

  .nav-links li {
    list-style-type: none;
    margin-bottom: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
  }
  
  .navbar {
    height: 58px;
  }

  .nav-links {
    margin-top: 1.8rem;
  }

  .link {
    font-size: 2rem;
  }

  .logo {
    margin-top: 1.5rem;
  }

  .page {
    width: 100%;
    height: 100%;
    position: absolute;
  }

  .bars {
    display: none;
  }

  .logo {
    display: flex;
    justify-content: center;
    text-align: center;
    width: 40px;
    height: 20px;
    background-color: rgb(255, 255, 255);
    border-radius: 50%;
    text-align: center;
    font-size: 2rem;
    margin-left: 1.5rem;
    margin-bottom: 25px;
    position: absolute;
  }

  .faM {
    color: #6f1e2c;
  }
}

@media screen and (min-width: 991px) {

  .navbar {
    height: 58px;
  }

  .nav-links {
    margin-top: 1.8rem;
  }

  .link {
    font-size: 18px;
  }
  
  .logo {
    margin-top: 1.5rem;
  }
}

@media screen and (min-width: 1300px) {
  .link {
    font-size: 2rem;
  }
}
