$primary-color: rgb(207, 83, 122);

@import 'animate.css';




@font-face{
  font-family: 'Nunito';
  src: url('./assets/fonts/Nunito.ttf') format('woff2')
}

