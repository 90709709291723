//Mobile first

@media screen and (max-width: 799px) {
  .nav-container {
    transform: translateX(-100%);
    transition: all 0.45s;
    display: none;
    z-index: 99999;

  &.open {
    display: block;
    transform: translateX(0);
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  }

  .nav-links.active {
    display: block;
    z-index: 99999;
  }

  .navbar {
    background: #7c2c3a;
    display: flex;
    flex-direction: column;
    
  }
  
  .logo {
    display: block;
    padding: 8px 0;
  }

  img {
    display: block;
    margin: 8px auto;
    width: 40px;
    height: auto;
  }

  nav {
    display: block;
    text-align: center;
    position: absolute;
    height: 210px;
    top: 50%;
    margin-top: -120px;
    width: 100%;

    .link {
      font-size: 22px;
      color: white;
      display: block;
      line-height: 51px;
      height: 51px;
      position: relative;
      text-decoration: none;
    }
  }

  .bars {
    position: absolute;
    top: 1.5rem;
    right: 2rem;
    flex-direction: column;
    justify-content: space-between;
    font-size: 25px;
    cursor: pointer;
  }

  .nav-links li {
    list-style: none;
    padding: 5px 40px;
    text-align: center;
  }

  .link {
    color: white;
    font-size: 18px;
    text-decoration: none;
  }

  .nav-container {
    z-index: 99999;
    cursor: pointer;
    float: right;
  }
}

@media screen and (min-width: 800px) {
  .navbar {
    height: 50px;
    background: #7c2c3a;
    margin-top: -1rem;
  }

  .nav-links {
    margin-top: 1.5rem;
  }

  .link {
    color: white;
    font-size: 2rem;
    font-size: 1.1rem;
    text-decoration: none;
    
  }

  .link:hover{
    color: white;
    font-weight: 600;
  }
  
  .logo {
    display: block;
    padding: 8px 0;
    margin-top: 1.2rem;
  }

  img {
    display: block;
    margin: 8px auto;
    width: 40px;
    height: auto;
  }

  nav {
    display: block;
    text-align: center;
    position: absolute;
    height: 210px;
    top: 50%;
    margin-top: -120px;
    width: 100%;

  a:hover {
        color: white;
        font-weight: 600;
        text-decoration: none;
        
      }

  }

  .bars {
    cursor: pointer;
    float: right;
    font-size: 20px;
    margin-top: 4px;
    margin-right: 15px;
  }

  ul {
    display: flex;
    flex-direction: row;
    justify-content: right;
  }

  .nav-links li {
    list-style: none;
    padding: 5px 40px;
  }

  ul li > a {
    color: white;
    font-size: 18px;
    text-decoration: none;
  }

  .nav-container {
    z-index: 99999;
    cursor: pointer;
    float: right;
  }
}
